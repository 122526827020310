import { useState } from "react";
import { motion } from "framer-motion";

export function Example() {
  const [degrees, setDegrees] = useState(0);

  function add45() {
    setDegrees(degrees + 45);
  }

  return (
    <motion.div
      style={{
        width: 150,
        height: 150,
        borderRadius: 30,
        backgroundColor: "#fff",
        cursor: "pointer"
      }}
      animate={{ rotate: degrees }}
      onTap={add45}
    />
  );
}
